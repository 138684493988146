import logo from './logo.svg';
import './App.css';
import Navbar from './Navbar';
import './tailwind.css';
import FeatureCard from './FeatureCard';
import FAQ from './FAQ';
import check from './assets/check.png'; 
import nope from './assets/nope.png';
import aiEditingDemo from './assets/aiEditingDemo.gif';
import projectManagementTools from './assets/projectManagementTools.gif';
import trainingDataDemo from './assets/trainingDataDemo.gif';
import rfpScoringDemo from './assets/rfpScoringDemo.gif';
import grantDatabaseDemo from './assets/grantDatabaseDemo.gif';
import emailNewsletter from './assets/emailNewsletter.gif';



function App() {
  return (
    <div className="App max-w-full overflow-x-hidden">
      <Navbar></Navbar>
      
      {/* Hero Header*/}
      <div className='h-[50vh] mt-28'>
        <p className='text-5xl sm:text-7xl serif mx-auto max-w-[90%] md:max-w-[50%] text-center'>
        Introducing Daisy: the first AI grant writer
        </p>
        <p className='mt-6 text-xl text-gray-600 mx-auto max-w-[90%] md:max-w-[50%] text-center'>
        Win more grant funding for your nonprofit, by utilizing AI for grant research and writing
        </p>

        <button 
          className='bg-indigo-600 mt-12 text-xl text-white py-3 px-8 rounded-lg hover:bg-indigo-700 outline outline-2 outline-indigo-500'
          onClick={() => window.location.href = "https://grantmagicapp.com/signup"}
        >Get Started</button>
      </div>

    {/* Magic Features */}
    <div className='bg-gray-100 h-[100vh] sm:h-[100vh] mt-20 flex flex-col items-center'>
      <p className='text-indigo-600 mt-20 text-md font-semibold'>DISCOVER GRANTS</p>
      <p className='serif text-5xl mt-6 px-4 text-center'>Find new grants without trying</p>
      <p className='text-xl mt-6 text-gray-600 max-w-[90%] md:max-w-[60%] text-center'>Daisy scrapes the internet for new grant opportunities, then sends you personalized updates on grants that are a good fit for your nonprofit</p>
      <div className='w-full mt-10'>
      <img 
        src={emailNewsletter} 
        alt="Editor" 
        className="mx-auto w-[80%] sm:w-[60%]" 
        style={{ height: 'auto', borderRadius: '16px', boxShadow: '0 10px 14px rgba(0, 0, 0, 0.4)' }} 
      />
      </div>
    </div>

    {/* Magic Features */}
    <div className='h-[75vh] sm:h-[100vh] mt-20 flex flex-col items-center'>
      <p className='text-indigo-600 mt-20 text-md font-semibold'>FIND FUNDERS</p>
      <p className='serif text-5xl mt-6 px-4 '>Discover funders who love you</p>
      <p className='text-xl mt-6 text-gray-600 max-w-[90%] md:max-w-[60%] text-center'>Browse millions of actual grant awards, to find funders who have funded nonprofits like yours</p>
      <div className='w-full mt-10'>
      <img 
        src={grantDatabaseDemo} 
        alt="Editor" 
        className="mx-auto w-[80%] sm:w-[60%]" 
        style={{ height: 'auto', borderRadius: '16px', boxShadow: '0 10px 14px rgba(0, 0, 0, 0.4)' }} />
      </div>
    </div>
    

    {/* Other Features */}
    <div className='bg-gray-100 h-auto mt-20 flex flex-col items-center'>
      <p className='text-indigo-600 mt-20 text-md font-semibold'>PREMIUM FEATURES</p>
      <p className='serif text-5xl mt-6 px-4 text-center'>What else can Daisy do?</p>
      
      {/* Example Cards */}
      <div className='flex flex-col sm:flex-row justify-center mx-8 sm:mx-32 my-8 space-y-12 sm:space-y-0 sm:space-x-12'>
        <FeatureCard 
          image={rfpScoringDemo}
          title="Discover grant opportunities"
          subtitle="Daisy can score RFPs, to help discover grant opportunities you're eligible for"
        />

        <FeatureCard 
          image={projectManagementTools}
          title="Keep you organized"
          subtitle="Manage all your grants in one place. Sleep well knowing you'll never miss a grant deadline again"
        />
      </div>
      <div className='flex flex-col sm:flex-row justify-center mx-8 sm:mx-32 my-8 space-y-12 sm:space-y-0 sm:space-x-12'>
        <FeatureCard 
          image={aiEditingDemo}
          title="Edit your work directly"
          subtitle="Daisy can edit your work in seconds, personalizing answers, or adjusting length to meet word count requirements"
        />
        <FeatureCard 
          image={trainingDataDemo}
          title="Learn from your past work"
          subtitle="No extra effort needed. Simply upload past proposals, presentations, and reports for Daisy to study and remix for future proposals"
        />

      </div>      
    </div>

    {/* Pricing */}
    <div className='h-auto bg-indigo-900 flex flex-col items-center'>
      <p className='text-white mt-20 text-md font-semibold'>PRICING</p>
      <p className='serif text-5xl mt-6 px-4 text-center text-white'>How much does Daisy cost?</p>
      
      {/* Pricing Cards */}
      <div className='flex flex-col md:flex-row justify-center mx-4 md:mx-16 my-8 space-y-12 md:space-y-0 md:space-x-4'>
      
        {/* Free Plan */}
        <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 w-full sm:w-1/3 flex flex-col justify-between">
            <div className="p-4">
              <p className="text-gray-500 text-lg text-left ">Starter</p>
              <h2 className="text-5xl text-left serif mt-2">Free</h2> 
              <p className="text-gray-500 text-left mt-2 text-sm italic">In your spare time, find possible funders</p>
              <hr className="my-4 border-t border-gray-200 w-full" />                                                            
              <ul className="text-gray-500 text-left mt-2 space-y-4">
                <li>✅ 1M+ grant awards database</li>                        
                <li>✅ Grants / tasks organizer</li>
                <li>❌ AI personalized grant discovery</li>                                                
                <li>❌ AI magic drafts (LOIs, cover letters)</li>                                                                    
                <li>❌ Priority support</li>
                <li>❌ Create multiple programs</li>                        
                <li>❌ Add teammates to your organization</li>
                <li>❌ Dedicated account manager</li>
              </ul>
            </div>
            <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={() => window.open('https://grantmagicapp.com/signup', '_blank')}>Try Free</button>
        </div>
        
        {/* Premium Plan */}
        <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 w-full sm:w-1/3 flex flex-col justify-between">
          <div className="p-4">
            <p className="text-gray-500 text-lg text-left ">Premium</p>
              <h2 className="text-5xl text-left serif mt-2">$5 / month</h2>            
              <p className="text-gray-500 text-left mt-2 text-sm italic">Win more grants, for the price of a latte</p>
              <hr className="my-4 border-t border-gray-200 w-full" />    
              <ul className="text-gray-500 text-left mt-2 space-y-4">
                <li>✅ 1M+ grant awards database</li>                        
                <li>✅ Grants / tasks organizer</li>
                <li>✅ AI personalized grant discovery</li>                                                
                <li>✅ AI magic drafts (LOIs, cover letters)</li>                                                                    
                <li>✅ Priority support</li>                                                
                <li>✅ Create up to 3 programs</li>
                <li>❌ Add teammates to your organization</li>
                <li>❌ Dedicated account manager</li>
              </ul>                                                        
          </div>
          <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={() => window.open('https://satodadj.gumroad.com/l/rlqzat', '_blank')}>Get Access Now</button>
        </div>

        {/* Enterprise Plan */}
        <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 w-full sm:w-1/3">
          <div className="p-4">
          <p className="text-gray-500 text-lg text-left ">Enterprise</p>
            <h2 className="text-5xl text-left serif mt-2">Contact Us</h2>            
            <p className="text-gray-500 text-left mt-2 text-sm italic">Tailored solutions for your organization</p>
            <hr className="my-4 border-t border-gray-200 w-full" />    
            <ul className="text-gray-500 text-left mt-2 space-y-4">
                <li>✅ 1M+ grant awards database</li>                        
                <li>✅ Grants / tasks organizer</li>
                <li>✅ AI personalized grant discovery</li>                                                
                <li>✅ AI magic drafts (LOIs, cover letters)</li>                                                                    
                <li>✅ Priority support</li>                                                                  
                <li>✅ Create up to 10 programs</li>
                <li>✅ Add teammates to your organization</li>
                <li>✅ Dedicated account manager</li>                                                                        
            </ul>                                                        
          </div>
          <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={() => window.open('https://tally.so/r/mDVa5j', '_blank')}>Contact Us</button>
        </div>
      </div>
    </div>

    {/* FAQ Section */}
    <div id="faq" className='py-20 bg-gray-100'>
      <FAQ />
    </div>

    {/* Footer */}
    <footer className='bg-gray-100 text-gray-400 py-6 text-center shadow-lg'>
      <p>&copy; 2023 GrantMagic. All rights reserved.</p>
    </footer>
    </div>
  );
}

export default App;